import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Row,
  Col,
  Breadcrumb,
  Button,
  message,
  Divider,
} from "antd";
import AddressFormModal from "components/AddressFormModal/AddressFormModal";
import Loader from "components/Loader/Loader";
import { useOrganization } from "api/organization";
import {
  useCreateShippingLocation,
  useUpdateShippingLocation,
} from "api/shippingLocation";
import { useCreateSamplingLocation } from "api/samplingLocation";
import SamplingLocFormModal from "./SamplingLocFormModal";

const { Text, Title } = Typography;

const AccountDetails = ({ match: { params } }) => {
  const [modalAction, setModalAction] = useState(); // "add" new or "edit" existing address
  const [showAddressFormModal, setShowAddressFormModal] = useState(false);
  const [showSamplingLocModal, setShowSamplingLocModal] = useState(false);
  const [selectedLocationIndex, setSelectedLocationIndex] = useState();
  const { mutate: createShippingLocation } = useCreateShippingLocation();
  const { mutate: updateShippingLocation } = useUpdateShippingLocation();

  const {
    data: orgDetails,
    isLoading,
    refetch: refetchOrgDetails,
  } = useOrganization({ id: params.id }, { enabled: !!params.id });

  const {
    mutate: createSamplingLoc,
    isLoading: createSamplingLocLoading,
  } = useCreateSamplingLocation();

  const handleEditAddressClick = (locationIndex) => {
    setModalAction("edit");
    setShowAddressFormModal(true);
    setSelectedLocationIndex(locationIndex);
  };

  const handleAddAddressClick = () => {
    setModalAction("add");
    setShowAddressFormModal(true);
  };

  const onSuccess = (model, actionText) => {
    setShowAddressFormModal(false);
    setShowSamplingLocModal(false);
    message.success(`${model} successfully ${actionText}`);
    refetchOrgDetails();
  };

  const handleAddressFormSubmit = (addressFormValue) => {
    if (modalAction === "add") {
      createShippingLocation(
        { organization: params.id, ...addressFormValue },
        { onSuccess: onSuccess("Address", "created") }
      );
    } else if (modalAction === "edit") {
      updateShippingLocation(addressFormValue, {
        onSuccess: onSuccess("Address", "updated"),
      });
    }
  };
  const addressText = (text) => text && <span>{text}</span>;

  const handleAddSamplingLocFormSubmit = (formValues) => {
    createSamplingLoc(
      {
        sampling_location_name: formValues.sampling_location_name,
        organization: orgDetails.id,
      },
      {
        onSuccess: () => {
          onSuccess(
            `Sampling location ${formValues.sampling_location_name}`,
            "created"
          );
        },
        onError: () => {
          message.error(
            `Unable to create ${formValues?.sampling_location_name} location. Please try again.`
          );
        },
      }
    );
  };

  if (isLoading) return <Loader />;

  return !orgDetails?.organization_name ? (
    <Title level={2}>Error Retrieving Organization Details</Title>
  ) : (
    <div className="mx-4">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/organizations">Organizations</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{orgDetails.organization_name}</Breadcrumb.Item>
      </Breadcrumb>
      <Title level={2} className="text-thick">
        {orgDetails.organization_name}
        <Text className="text-thin"> ({orgDetails.display_id})</Text>
      </Title>
      <Title level={3}>Shipping Addresses</Title>
      {orgDetails.shipping_locations.length ? (
        <>
          <Row className="mt-2">
            {orgDetails.shipping_locations.map((loc, index) => (
              <Col sm={12} md={9} lg={9} xl={8} key={index}>
                <Row className="mt-1">
                  <Title level={5} style={{ marginBottom: 0 }} strong="true">
                    <Button
                      type="link"
                      className="px-0 text-lg"
                      style={{ whiteSpace: "normal" }}
                      onClick={() => handleEditAddressClick(index)}
                    >
                      {loc.name ? loc.name : "Address name not found"}
                    </Button>
                    &nbsp;- {loc.active ? "Active" : "Inactive"}
                  </Title>
                </Row>
                <Row className="mb-5 flex-col">
                  {addressText(loc.address_line_1)}
                  {addressText(loc.address_line_2)}
                  {loc.city && loc.state_id && (
                    <div>
                      {loc.city}, {loc.state_id}
                      &nbsp;
                      {addressText(loc?.postal_code)}
                    </div>
                  )}
                  {addressText(loc.country_id)}
                </Row>
              </Col>
            ))}
          </Row>
        </>
      ) : (
        <div className="mb-3">No Shipping Addresses on File</div>
      )}
      <Button onClick={handleAddAddressClick}>+ Add Address</Button>
      <AddressFormModal
        visible={showAddressFormModal}
        modalAction={modalAction}
        locationDetails={orgDetails.shipping_locations[selectedLocationIndex]}
        onSubmit={handleAddressFormSubmit}
        onCancel={() => setShowAddressFormModal(false)}
      />
      <Divider />
      <Title level={3}>Sampling Locations</Title>
      <Row className="my-2">
        {orgDetails.sampling_locations.length ? (
          <>
            {orgDetails.sampling_locations.map((loc, index) => (
              <Col sm={12} md={9} lg={9} xl={8} key={index}>
                <Row className="mt-1">
                  <Title level={5} strong="true">
                    <Link to={`/admin/sampling-locations/${loc.id}`}>
                      {loc.sampling_location_name || ""}
                    </Link>
                  </Title>
                </Row>
              </Col>
            ))}
          </>
        ) : (
          <div className="mb-3">No Sampling Locations on File</div>
        )}
      </Row>
      <Button onClick={() => setShowSamplingLocModal(true)}>
        + Add Sampling Location
      </Button>
      <SamplingLocFormModal
        visible={showSamplingLocModal}
        onSubmit={handleAddSamplingLocFormSubmit}
        onCancel={() => setShowSamplingLocModal(false)}
        createSamplingLocLoading={createSamplingLocLoading}
        orgName={orgDetails.organization_name}
      />
      <Divider />
      <Title level={3}>Quick Links</Title>
      <Row>
        <ul>
          <li>
            <Link
              to={`/admin/kits-by-organization?organization_name=${orgDetails.organization_name}`}
            >
              Kits for {orgDetails.organization_name}
            </Link>
          </li>
        </ul>
      </Row>
    </div>
  );
};

export default AccountDetails;
