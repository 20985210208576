import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Tooltip, Tag } from "antd";
import Text from "antd/lib/typography/Text";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { LogSampleButton } from "components/Buttons/TableCellButton";
import ProductTag from "components/ProductTag";
import ProgramTag from "components/ProgramTag";
import KitAssays from "components/KitAssays";
import KitNotes from "components/KitNotes";
import SelectionStatusTag from "components/SelectionStatusTag";
import { customChoiceFilterProps } from "components/Table/CustomChoiceFilter";
import {
  METHOD_EMPTY,
  METHOD_NOT_EMPTY,
  METHOD_TIME_RANGE_EXCLUSIVE_END,
  METHOD_TEXT_CONTAINS,
  METHOD_CUSTOM_FILTER,
  strapiFilterProps,
} from "components/Table/StrapiFilter";
import { ASSAY_COLOR_MAPPING } from "configs/constants";
import { trackingNumberLink } from "utils/components";
import { toLocaleString } from "utils/time";

import { programSelectionStatusFilterProps } from "components/ProgramSelectionStatusFilter";

export const selectionStatusTooltip = (
  <Text style={{ color: "white" }}>
    Key: <br /> <br />
    <Text strong underline style={{ color: "white" }}>
      Selected:
    </Text>
    This kit will be used for this program (e.x. included in NWSS export).
    <br />
    <br />
    <Text strong underline style={{ color: "white" }}>
      Eligible:
    </Text>
    This kit could be selected for the program, but has not been (hover over tag
    to see why).
    <br />
    <br />
    <Text strong underline style={{ color: "white" }}>
      Ambigous:
    </Text>
    We could not determine if this kit is eligible for the program (hover over
    tag to see why).
    <br />
    <br />
    <Text strong underline style={{ color: "white" }}>
      Ineligible:
    </Text>
    This kit is not eligible for this program (hover over tag to see why).
  </Text>
);

export const selectionStatusHeader = (
  <Tooltip title={selectionStatusTooltip}>
    <Text>Selection Status</Text>
    <QuestionCircleOutlined className="pl-2" />
  </Tooltip>
);

export const columnShippingKitID = () => {
  return {
    title: "Kit ID",
    dataIndex: [],
    render: (record) => (
      <Link to={`/kit/${record.id}`}>
        {record?.shipping_kit_id || `ID Pending`}
      </Link>
    ),
    key: "shipping_kit_id",
    sortKey: "shipping_kit_id",
    width: 105,
    ...strapiFilterProps({
      enabledFilterMethods: [
        METHOD_TEXT_CONTAINS,
        METHOD_EMPTY,
        METHOD_NOT_EMPTY,
      ],
      defaultFilterMethod: METHOD_TEXT_CONTAINS,
      searchPlaceholder: "Search Shipping Kit ID",
    }),
  };
};

export const columnKitID = () => {
  return {
    title: "Internal ID",
    dataIndex: ["kit_id"],
    key: "kit_id",
    width: 115,
    ...strapiFilterProps({
      enabledFilterMethods: [
        METHOD_TEXT_CONTAINS,
        METHOD_EMPTY,
        METHOD_NOT_EMPTY,
      ],
      defaultFilterMethod: METHOD_TEXT_CONTAINS,
      searchPlaceholder: "Search Internal Kit Id",
    }),
  };
};

export const columnKitConfiguration = () => {
  return {
    title: "Configuration",
    dataIndex: ["configuration", "name"],
    key: "configuration.name",
    render: (configuration) => configuration ?? "-",
    width: 115,
    ...strapiFilterProps({
      enabledFilterMethods: [
        METHOD_TEXT_CONTAINS,
        METHOD_EMPTY,
        METHOD_NOT_EMPTY,
      ],
      defaultFilterMethod: METHOD_TEXT_CONTAINS,
      searchPlaceholder: "Search Configuration Types",
    }),
  };
};

export const columnOrganization = (defaultFilterValue) => {
  return {
    title: "Organization",
    dataIndex: [
      "order",
      "order_placement",
      "organization",
      "organization_name",
    ],
    key: "order.order_placement.organization.organization_name",
    ...strapiFilterProps({
      enabledFilterMethods: [
        METHOD_TEXT_CONTAINS,
        METHOD_EMPTY,
        METHOD_NOT_EMPTY,
      ],
      defaultFilterMethod: METHOD_TEXT_CONTAINS,
      defaultFilterValue,
      searchPlaceholder: "Search Organization Name",
    }),
  };
};

export const columnSampleCollectionDate = () => {
  return {
    title: "Sample Collection Date",
    dataIndex: ["sample", "sample_collection_date_time"],
    key: "sample.sample_collection_date_time",
    render: (record) => (record ? toLocaleString(record) : ""),
    sortKey: "sample.sample_collection_date_time",
    width: 105,
    ...strapiFilterProps({
      enabledFilterMethods: [
        METHOD_TIME_RANGE_EXCLUSIVE_END,
        METHOD_EMPTY,
        METHOD_NOT_EMPTY,
      ],
      defaultFilterMethod: METHOD_TEXT_CONTAINS,
      searchPlaceholder: "Search Sample Collection Date",
    }),
  };
};

export const columnSelectionStatus = (programs) => {
  return {
    title: () => selectionStatusHeader,
    key: "selection_statuses",
    csvConfig: false,

    ...programSelectionStatusFilterProps(programs),

    render: (record) => {
      return (
        <>
          {record.selection_statuses?.map((x) => (
            <SelectionStatusTag
              key={x.id}
              status={x.selection_status}
              reason={x.reason}
              displayName={x.program?.name}
              shortDisplayName={x.program?.short_name}
            />
          ))}
        </>
      );
    },

    width: 250,
  };
};

export const columnAssays = (assays) => {
  return {
    title: "Assays",
    key: "assays",
    dataIndex: "products",
    csvConfig: { label: "Assays", value: "products.assay.name" },
    render: (record) => <KitAssays products={record} />,
    ...customChoiceFilterProps({
      choices: _.map(assays, (assay) => {
        return { label: assay.name, value: assay.id };
      }),

      toStrapiFilter: (assays) => {
        const filter = { "products.assay": assays };
        return { method: METHOD_CUSTOM_FILTER, value: filter };
      },

      renderChoice: (choice) => {
        return (
          <Tag color={ASSAY_COLOR_MAPPING[choice.label] || "black"}>
            {choice.label || "???"}
          </Tag>
        );
      },
    }),
  };
};

export const createKitTableColumns = (
  reviewStatusFilterOptions,
  handleLogSampleClicked,
  reviewStatuses,
  programs,
  assays,
  defaultDateReceivedFilterMethod,
  defaultDateReceivedFilterValue
) => [
  columnShippingKitID(),
  columnKitID(),
  columnKitConfiguration(),
  columnOrganization(),
  columnAssays(assays),
  {
    title: "Sampling Location",
    dataIndex: [],
    render: (record) => {
      const samplingLocation = record?.sample?.sampling_location_id;
      if (!samplingLocation?.id)
        return (
          <LogSampleButton onClick={() => handleLogSampleClicked(record)} />
        );
      return (
        <Link to={`/admin/sampling-locations/${samplingLocation?.id}`}>
          {samplingLocation?.sampling_location_name || ""}
        </Link>
      );
    },
    key: "sample.sampling_location_id.sampling_location_name",
    ...strapiFilterProps({
      enabledFilterMethods: [
        METHOD_TEXT_CONTAINS,
        METHOD_EMPTY,
        METHOD_NOT_EMPTY,
      ],
      defaultFilterMethod: METHOD_TEXT_CONTAINS,
      searchPlaceholder: "Search Sampling Location Name",
    }),
  },
  {
    title: "Kit Ship Date",
    dataIndex: ["date_shipped"],
    render: (record) => (record ? toLocaleString(record) : ""),
    key: "date_shipped",
    sorter: true,
    ...strapiFilterProps({
      enabledFilterMethods: [
        METHOD_TIME_RANGE_EXCLUSIVE_END,
        METHOD_EMPTY,
        METHOD_NOT_EMPTY,
      ],
    }),
  },
  {
    title: "Kit Received at Biobot",
    dataIndex: ["date_received"],
    render: (record) => (record ? toLocaleString(record) : ""),
    key: "date_received",
    defaultSortOrder: "descend",
    sorter: true,
    className: "data-cy-kit-received-biobot",
    ...strapiFilterProps({
      enabledFilterMethods: [
        METHOD_TIME_RANGE_EXCLUSIVE_END,
        METHOD_EMPTY,
        METHOD_NOT_EMPTY,
      ],
      defaultFilterMethod: defaultDateReceivedFilterMethod,
      defaultFilterValue: defaultDateReceivedFilterValue,
    }),
  },
  {
    title: "Shipping Name",
    dataIndex: ["order", "shipping_location", "name"],
    key: "order.shipping_location.name",
    sortKey: "order.shipping_location.name",
    ...strapiFilterProps({
      enabledFilterMethods: [
        METHOD_TEXT_CONTAINS,
        METHOD_EMPTY,
        METHOD_NOT_EMPTY,
      ],
      defaultFilterMethod: METHOD_TEXT_CONTAINS,
      searchPlaceholder: "Search Shipping Name",
    }),
  },
  {
    title: "Shipping Address Line 1",
    dataIndex: ["order", "shipping_location", "address_line_1"],
    key: "order.shipping_location.address_line_1",
    sortKey: "order.shipping_location.address_line_1",
    width: 105,
    ...strapiFilterProps({
      enabledFilterMethods: [
        METHOD_TEXT_CONTAINS,
        METHOD_EMPTY,
        METHOD_NOT_EMPTY,
      ],
      defaultFilterMethod: METHOD_TEXT_CONTAINS,
      searchPlaceholder: "Search Shipping Location Address",
    }),
  },
  {
    title: "Outbound Tracking Number",
    dataIndex: ["outbound_tracking_number"],
    key: "outbound_tracking_number",
    render: (records) => trackingNumberLink(records),
    sortKey: "outbound_tracking_number",
    width: 105,
    ...strapiFilterProps({
      enabledFilterMethods: [
        METHOD_TEXT_CONTAINS,
        METHOD_EMPTY,
        METHOD_NOT_EMPTY,
      ],
      defaultFilterMethod: METHOD_TEXT_CONTAINS,
      searchPlaceholder: "Search Outbound Tracking Number",
    }),
  },
  {
    title: "Inbound Tracking Number",
    dataIndex: ["inbound_tracking_number"],
    key: "inbound_tracking_number",
    render: (records) => trackingNumberLink(records),
    sortKey: "inbound_tracking_number",
    width: 105,
    ...strapiFilterProps({
      enabledFilterMethods: [
        METHOD_TEXT_CONTAINS,
        METHOD_EMPTY,
        METHOD_NOT_EMPTY,
      ],
      defaultFilterMethod: METHOD_TEXT_CONTAINS,
      searchPlaceholder: "Search Inbound Tracking Number",
    }),
  },
  columnSampleCollectionDate(),
  {
    title: "SKU",
    dataIndex: [],
    key: "products.product_sku",
    render: (record) => {
      return (
        <>
          {record.products?.map((x) => (
            <ProductTag sku={x.product_sku} productName={x.product_name} />
          ))}
        </>
      );
    },
    ...strapiFilterProps({
      enabledFilterMethods: [
        METHOD_EMPTY,
        METHOD_NOT_EMPTY,
        METHOD_TEXT_CONTAINS,
      ],
      defaultFilterMethod: METHOD_TEXT_CONTAINS,
      searchPlaceholder: "Search Kit SKU",
    }),
  },
  columnSelectionStatus(programs),
  {
    title: "Program Enrollments",
    dataIndex: ["sample", "sampling_location_id", "program_enrollment"],
    key: "sample.sampling_location_id.program_enrollment",
    render: (programs) =>
      programs?.map(
        ({
          program: { id, name, code, short_name: shortName },
          start_date: startDate,
          end_date: endDate,
        }) => (
          <ProgramTag
            key={id}
            displayName={name}
            shortDisplayName={shortName}
            code={code}
            startDate={startDate}
            endDate={endDate}
          />
        )
      ),
  },
  {
    title: "Log Review Status",
    dataIndex: ["sample", "review_status"],
    key: "sample.review_status",
    render: (val) => _.find(reviewStatuses, (item) => item.id === val)?.status,
    filters: reviewStatusFilterOptions,
    filterMultiple: false,
  },
  {
    title: "Log Reviewer",
    dataIndex: ["sample", "log_reviewed_by"],
    key: "sample.log_reviewed_by",
  },
  {
    title: "Log Review Notes",
    dataIndex: ["sample", "reviewer_notes"],
    key: "sample.reviewer_notes",
    sortKey: "sample.reviewer_notes",
    width: 500,
  },
  {
    title: "Kit Record Created",
    dataIndex: ["created_at"],
    render: (record) => toLocaleString(record),
    key: "created_at",
    sorter: true,
  },
  {
    title: "Kit Record Updated",
    dataIndex: ["updated_at"],
    render: (record) => toLocaleString(record),
    key: "updated_at",
    sorter: true,
  },
  {
    title: "Sample Submitted By",
    dataIndex: ["sample", "author", "email"],
    key: "sample.author.email",
  },
  {
    title: "Kit Notes",
    key: "kit_notes",
    dataIndex: [],
    render: (record) => (
      <KitNotes
        kit={record}
        onSave={() => {}}
        defaultValue={record.kit_notes}
      />
    ),
    csvConfig: { label: "Notes", value: "kit_notes" },
    ...strapiFilterProps({
      enabledFilterMethods: [
        METHOD_EMPTY,
        METHOD_NOT_EMPTY,
        METHOD_TEXT_CONTAINS,
      ],
      defaultFilterMethod: METHOD_NOT_EMPTY,
      searchPlaceholder: "Search Notes",
    }),
  },
];
