import { Link, withRouter } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import { PageHeader, Table } from "antd";

import { useKitCount, useKitList } from "api/kit";
import { useProgramList } from "api/program";

import { useTableQueryParams } from "components/Table/helpers";
import {
  METHOD_EMPTY,
  METHOD_NOT_EMPTY,
  METHOD_TIME_RANGE_EXCLUSIVE_END,
  METHOD_TEXT_CONTAINS,
  strapiFilterProps,
} from "components/Table/StrapiFilter";

import { toLocaleString } from "utils/time";

import {
  columnShippingKitID,
  columnKitID,
  columnKitConfiguration,
  columnOrganization,
  columnSampleCollectionDate,
  columnSelectionStatus,
} from "pages/admin/Kits/helpers";

const KitsByOrganization = () => {
  const [organizationName, setOrganizationName] = useQueryParam(
    "organization_name",
    StringParam
  );

  const { queryParams, pagination, handleTableChange } = useTableQueryParams({
    defaultFilters: {
      "order.order_placement.organization.organization_name": [
        {
          method: METHOD_TEXT_CONTAINS,
          value: organizationName,
        },
      ],
    },
    defaultPageSize: 50,
    defaultSort: "date_received:DESC",
  });

  const { data: kitsCount } = useKitCount(queryParams);

  const kitsList = useKitList({
    ...queryParams,
    _joins: [
      "configuration",
      "products",
      "organization",
      "sample",
      "sample.author",
      "order.shipping_location",
      "sample.sampling_location_id.program_enrollment.program",
      "selection_statuses.program",
    ],
  });

  const { data: programs } = useProgramList();

  const columns = [
    columnShippingKitID(),
    columnKitID(),
    columnKitConfiguration(),
    columnOrganization(organizationName),
    {
      title: "Sampling Location",
      key: "sample.sampling_location_id.sampling_location_name",
      dataIndex: [],

      render: (record) => {
        const samplingLocation = record?.sample?.sampling_location_id;

        return (
          <Link to={`/admin/sampling-locations/${samplingLocation?.id}`}>
            {samplingLocation?.sampling_location_name || ""}
          </Link>
        );
      },

      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TEXT_CONTAINS,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Sampling Location Name",
      }),
    },
    {
      title: "Date Kit Received",
      key: "date_received",
      dataIndex: ["date_received"],

      render: (record) => (record ? toLocaleString(record) : ""),

      defaultSortOrder: "descend",
      sorter: true,

      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TIME_RANGE_EXCLUSIVE_END,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_TIME_RANGE_EXCLUSIVE_END,
      }),
    },
    columnSampleCollectionDate(),
    {
      title: "Sample Log",
      dataIndex: [],
      key: "sample",
      render: (record) => {
        return record.sample?.id ? (
          <Link
            to={`/kit/${record.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Sample Log Details
          </Link>
        ) : (
          "Pending"
        );
      },
    },
    columnSelectionStatus(programs),
  ];

  return (
    <div className="kits-by-organization" style={{ height: "100%" }}>
      <PageHeader
        title={`Kits for ${organizationName}`}
        className="mb-3"
        style={{
          padding: 0,
          marginBottom: "15px",
        }}
      />

      <Table
        className="kits-by-organization-table"
        rowKey="id"
        loading={kitsList.isLoading}
        columns={columns}
        pagination={{ ...pagination, total: kitsCount }}
        dataSource={kitsList.data}
        onChange={(pagination, filters, sorter) => {
          const organization_name =
            filters["order.order_placement.organization.organization_name"]?.[0]
              .value;

          setOrganizationName(organization_name);

          handleTableChange(pagination, filters, sorter);
        }}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default withRouter(KitsByOrganization);
