import _ from "lodash";
import React from "react";
import { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Col,
  Collapse,
  Form,
  PageHeader,
  Row,
  Switch,
  Table,
  Tooltip,
  Typography,
} from "antd";
import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import Search from "antd/lib/input/Search";
import { orderServiceCustomRoute } from "../../../api/orderService";
import SelectionStatusTag from "components/SelectionStatusTag";
import KitAssays from "components/KitAssays";
import KitNotes from "components/KitNotes";
import Loader from "components/Loader/Loader";
import ProgramFilterModel from "./ProgramFilterModal";
import { saveBrowserDataToFile } from "../../../utils";

const { Link, Text } = Typography;
const { Panel } = Collapse;

const KitLookupPage = () => {
  const [form] = Form.useForm();
  const [currLookupData, setCurrLookupData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedOnly, setSelectedOnly] = useState(false);
  const [programModalOpen, setProgramModalOpen] = useState(false);
  const [programFilters, setProgramFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const searchRef = useRef(null);

  const notReceivedWarning = (record) => (
    <>
      {!record.date_received && record.id ? (
        <>
          <Text type="danger">
            <Tooltip className="mr-1" title="Kit has not been received yet!">
              &nbsp;
              <ExclamationCircleOutlined />
            </Tooltip>
          </Text>
        </>
      ) : (
        <></>
      )}
    </>
  );

  const columns = [
    {
      title: "Lookup Key",
      key: "lookupId",
      dataIndex: "lookupId",
      width: "110px",
    },
    {
      title: "Shipping Kit Id",
      key: "shipping_kit_id",
      width: "125px",
      render: (record) => (
        <>
          {notReceivedWarning(record)}
          {record.id ? (
            <Link href={`/kit/${record.id}`} target="_blank">
              {record.shipping_kit_id ?? "ID Pending"}
            </Link>
          ) : (
            <Text>{record.shipping_kit_id ?? "ID Pending"}</Text>
          )}
        </>
      ),
    },
    {
      title: "Assays",
      key: "products",
      width: "100px",
      render: (record) => (
        <>
          {notReceivedWarning(record)}
          <KitAssays products={record.products} />
        </>
      ),
    },
    {
      title: "Customer",
      key: "kit-customer",
      width: "275px",
      render: (record) => (
        <>
          <Link
            href={`/organizations/${record.order.order_placement.organization.id}`}
            target="_blank"
          >
            {record.order.order_placement.organization.organization_name}
          </Link>
          <br />
          {record.order.order_placement.organization.organization_name
            ? `\u{21B3}`
            : ""}{" "}
          {!_.isNil(record.sample) ? (
            <Link
              href={`sampling-locations/${record.sample.sampling_location_id.id}`}
              target="_blank"
            >
              {record.sample.sampling_location_id.sampling_location_name}
            </Link>
          ) : (
            <Text type="warning">
              <Tooltip
                className="mr-1"
                title="SKU Assignment may be ambiguous until sample log is submitted."
              >
                <ExclamationCircleOutlined />
              </Tooltip>
              No Sample Log
            </Text>
          )}
        </>
      ),
    },
    {
      title: "Selected Programs",
      width: "25%",
      width: "275px",
      render: (record) => {
        return (
          <>
            {notReceivedWarning(record)}
            {record.selection_statuses?.map((x) => (
              <SelectionStatusTag
                key={x.id}
                status={x.selection_status}
                reason={x.reason}
                displayName={x.program?.name}
                shortDisplayName={x.program?.short_name}
              />
            ))}
          </>
        );
      },
    },
    {
      title: "Notes",
      key: "kit-notes",
      width: "100px",
      render: (record) => (
        <>
          {
            // Only allow editing notes when lookup is for a single kit and was successful
            currLookupData.length > 1 ||
            record.shipping_kit_id === "No Kit found" ? (
              <Text>{record.kit_notes}</Text>
            ) : (
              <KitNotes
                kit={record}
                onSave={handleNotesUpdate}
                defaultValue={record.kit_notes}
              />
            )
          }
        </>
      ),
    },
    {
      title: "R&D?",
      dataIndex: "rndEligibility",
      key: "rndEligibility",
      width: "75px",
      render: (val) => <Text>{val ? "Yes" : "No"}</Text>,
    },
    {
      title: "Date Received",
      dataIndex: "date_received",
      key: "date_received",
      width: "150px",
      render: (val) => {
        if (_.isNil(val)) {
          return "-";
        }
        const dateReceived = new Date(val);
        return <Text>{dateReceived.toLocaleDateString()}</Text>;
      },
    },
  ];

  const handleNotesUpdate = (newNotes) => {
    setCurrLookupData([{ ...currLookupData[0], kit_notes: newNotes }]);
  };

  const fetchKitLookupData = async (lookupValue) => {
    if (_.isEmpty(lookupValue)) {
      return;
    }

    // Add the previous lookup to the top of the history table before setting a loading spinner
    setTableData(currLookupData.concat(tableData));
    setCurrLookupData([]);
    setIsLoading(true);

    // Fetch results from the API and then add them as the first row of table
    await orderServiceCustomRoute({
      model: "kits",
      path: "kit-lookup",
      method: "get",
      params: {
        lookupIds: lookupValue,
        selectedOnly: selectedOnly,
        filteredPrograms: programFilters.join(","),
      },
    })
      .then((kitLookupData) => {
        // Should never be empty because of custom null response, but just in case
        if (!_.isEmpty(kitLookupData)) {
          // Add the previous lookup to the top of the history table before setting new data
          setTableData(currLookupData.concat(tableData));
          setCurrLookupData(kitLookupData);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(`Error attempting to fetch kit data`);
        console.log(e);
        setIsLoading(false);
      });

    // Clear out search box and refocus on it
    form.resetFields(["lookup_id"]);
    searchRef.current.focus();
  };

  const handleSelectedOnlySwitchChange = async (switchValue) => {
    setSelectedOnly(switchValue);
  };

  const handleOpenProgramsModal = async () => {
    setProgramModalOpen(true);
  };

  const handleProgramFiltersOk = async (programFilters) => {
    setProgramFilters(programFilters);
    setProgramModalOpen(false);
  };

  const handleProgramFiltersCancel = async () => {
    setProgramModalOpen(false);
  };

  const getUniqueAssays = (kit) => {
    const uniqueAssays = _.uniqBy(kit.products, (p) => p.assay?.id);
    return _.map(uniqueAssays, (p) => p.assay.name);
  };

  const handleExport = () => {
    // Create a Blob containing the data
    const allRawLookupData = currLookupData.concat(tableData);

    const headerRow =
      "Lookup Key,Shipping Kit ID,Assays,Organization,Sampling Location,Programs,R&D,Notes\n";
    const csvData = allRawLookupData.map((obj) => {
      const lookupKey = obj.lookupId;
      const shippingKitId = obj.shipping_kit_id;
      const organization = obj.organization?.organization_name || "";
      const samplingLocation =
        obj.sample?.sampling_location_id?.sampling_location_name;
      const programs =
        obj.selection_statuses
          ?.map((status) => {
            return `${status.program?.code}:${status.selection_status}`;
          })
          .join(";") || "";
      const assays = getUniqueAssays(obj);
      const rndEligibility = obj.rndEligibility;
      const notes = obj.kit_notes;

      const fullExportRow = `${lookupKey},${shippingKitId},"${assays}",${organization},${samplingLocation},${programs},${rndEligibility},"${notes}"`;

      return `${fullExportRow}\n`;
    });
    const fullData = headerRow.concat(csvData.join(""));

    // Construct a filename for the export
    const today = new Date();
    const exportName = `kit_lookup_${today.toLocaleDateString()}_${today.toLocaleTimeString()}`;

    saveBrowserDataToFile(fullData, exportName, "text/csv");
  };

  return (
    <div className="admin-kits-page" style={{ height: "100%" }}>
      <PageHeader title="Kit Lookup Table" />

      <Row gutter={16} align="left" className="mb-1 ml-2">
        <Col span={1} />
        <Col span={20}>
          <Form form={form}>
            <Row align="bottom">
              <Col span={10}>
                <Form.Item
                  data-cy="lookup_id"
                  name="lookup_id"
                  label={
                    <>
                      <Text>Kit or Tube ID</Text>
                      <Tooltip title="This can be either the shipping ID or internal ID for either the kit or tube">
                        <QuestionCircleOutlined className="pl-1" />
                      </Tooltip>
                    </>
                  }
                >
                  <Search
                    placeholder="Enter kit or tube id"
                    size="medium"
                    ref={searchRef}
                    autoComplete={"off"}
                    type={"search"}
                    enterButton
                    onPressEnter={(e) => {
                      e.preventDefault();
                      fetchKitLookupData(e.target.value);
                    }}
                    onSearch={(value) => {
                      fetchKitLookupData(value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  data-cy="selected_only"
                  name="selected_only"
                  label="Only return 'selected' programs?"
                >
                  <Switch
                    onChange={handleSelectedOnlySwitchChange}
                    checkedChildren="Selected Only"
                    unCheckedChildren="All Statuses"
                  />
                </Form.Item>
              </Col>
              <Col span={2} />
              <Col span={9}>
                <Form.Item
                  data-cy="filtered_programs"
                  label={
                    <>
                      <Text>Program Filters</Text>
                      <Tooltip title="To only see a subset of programs on lookup. Only applies to new searches, will not affect history table.">
                        <QuestionCircleOutlined className="pl-1" />
                      </Tooltip>
                    </>
                  }
                >
                  {programFilters?.join(", ")}
                </Form.Item>
                <Form.Item>
                  <Button onClick={handleOpenProgramsModal} type="primary">
                    Select Program Filters
                  </Button>
                </Form.Item>
              </Col>
              <Col span={1}>
                <Button className="mb-3" onClick={handleExport}>
                  Export Lookups
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={2} />
      </Row>

      {isLoading ? (
        <Loader />
      ) : (
        <Table
          className="admin-kit-lookup-table ml-3"
          rowKey="lookup-id"
          columns={columns}
          dataSource={currLookupData}
          pagination={false}
          scroll={{ x: "scroll" }}
          tableLayout={"fixed"}
        />
      )}

      <Collapse ghost className="ml-1 mt-3">
        <Panel
          header={
            <>
              <Text>Previous Lookups</Text>
              <Tooltip title="History is cleared when leaving or refreshing this page">
                <QuestionCircleOutlined className="pl-1" />
              </Tooltip>
            </>
          }
        >
          <Table
            className="admin-kit-lookup-history-table ml-3"
            rowKey="history-id"
            columns={columns.slice(0, 5).concat(columns.slice(6))} // Don't allow modifying notes of previous lookups
            dataSource={tableData}
            pagination={false}
            scroll={{ x: "min-content" }}
          />
        </Panel>
      </Collapse>

      <ProgramFilterModel
        visible={programModalOpen}
        onOk={handleProgramFiltersOk}
        onCancel={handleProgramFiltersCancel}
      />
    </div>
  );
};

export default withRouter(KitLookupPage);
