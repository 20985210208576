import { Button, Col, Popover, Row, Typography, Modal, message } from "antd";
import _ from "lodash";
import Title from "antd/lib/typography/Title";
import { DownloadOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import StatusesTable from "./StatusesTable";
import ReviewWorkflowBreadcrumbs from "../ReviewWorkflowBreadcrumbs";
import {
  ResetAssayAction,
  StatusOverride,
  UpdateKitUnderReviewAction,
} from "reducers/reducerManualReview";
import { createKitReviewOrder, missingLogPredicate } from "../util";
import React, { useContext, useState } from "react";

import { AssayName, ManualReviewStatus } from "api/pipelinesAPI/types";
import fileDownload from "js-file-download";
import moment from "moment";
import { ReviewReducerContext } from "../reviewContext";
import {
  downloadKitsMissingLogs,
  submitReview,
} from "../../../../api/pipelinesAPI/reviews";

const AssaySummary = () => {
  const { state, dispatch } = useContext(ReviewReducerContext);
  const [open, setOpen] = useState(false);

  if (_.isNil(state.activeAssayName)) {
    throw new Error(`Unexpected null assay for state ${state}`);
  }

  const assayDisplay = state.activeAssayDisplayName();
  const assayState = state.activeAssayState();

  const statusesMissingLogs = assayState.pendingAliquotStatuses.filter(
    missingLogPredicate
  );
  const reviewableStatuses = assayState.pendingAliquotStatuses.filter(
    _.negate(missingLogPredicate)
  );
  const kitReviewOrder = createKitReviewOrder(reviewableStatuses);
  const numKitsReviewed = assayState.countKitsWithStatusOverrides(
    kitReviewOrder,
    reviewableStatuses
  );

  const handleNextKit = (kitName: string) => {
    const action = new UpdateKitUnderReviewAction(
      state.activeAssayName as AssayName,
      kitName
    );

    dispatch(action);
  };

  const handleSubmit = () => {
    const entries: [string, StatusOverride][] = Object.entries(
      assayState.aliquotOverridesMap
    );

    const overrides = entries.map(([aliquotId, override]) => {
      const id = Number(aliquotId); // aliquotOverridesMap has numeric keys but entries casts it to a string
      const pendingStatus = assayState.pendingAliquotStatuses.find(
        (s) => s.aliquot_status_id === id
      );

      if (_.isNil(pendingStatus)) {
        throw new Error(
          `An override for aliquot id ${id} has been submitted, but no there is no pending status for that
                   aliquot.`
        );
      }

      return {
        aliquot_id: pendingStatus.qpcr_aliquot.qpcr_aliquot_id,
        aliquot_name: pendingStatus.qpcr_aliquot.aliquot_name,
        status_override: override.status as ManualReviewStatus,
        notes: override.notes,
      };
    });

    submitReview(state, overrides)
      .then((response) => {
        fileDownload(
          response.data,
          `overrides_${state.activeAssayName}_${moment.utc().toISOString()}.csv`
        );

        hideModal();
        message.success("Overrides submitted!");
        dispatch(new ResetAssayAction(state.activeAssayName as AssayName));
      })
      .catch((err) => {
        message.error("Error submitting overrides.");
      });
  };

  const handleDownloadKitsMissingLogs = () => {
    downloadKitsMissingLogs(state)
      .then((response) => {
        fileDownload(
          response.data,
          `kits_missing_logs_${
            state.activeAssayName
          }_${moment.utc().toISOString()}.csv`
        );
      })
      .catch((err) => {
        message.error("Error downloading kits with missing logs.");
      });
  };

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const missingLogTip = (
    <Typography.Text>
      If a kit is missing a log its aliquots can't be associated with the
      location metadata needed to perform manual review.
    </Typography.Text>
  );

  // @ts-ignore
  return (
    <>
      <Row justify="space-between" className="ml-3">
        <Col>
          <ReviewWorkflowBreadcrumbs />
        </Col>
        <Col>
          <Typography.Text>
            {numKitsReviewed} of {kitReviewOrder.length} kits reviewed
          </Typography.Text>
          <Button
            type="primary"
            onClick={(_) => handleNextKit(assayState.nextKit(kitReviewOrder))}
            className="ml-2"
            ghost={numKitsReviewed > 0}
          >
            {numKitsReviewed === 0 ? "Start Review" : "Continue Review"}
          </Button>
          {numKitsReviewed > 0 && (
            <Button
              type="primary"
              onClick={(_) => showModal()}
              className="ml-2"
            >
              Submit Overrides
            </Button>
          )}
          <Modal
            title="Submit Status Overrides"
            open={open}
            onOk={handleSubmit}
            onCancel={hideModal}
            okText="Submit"
            cancelText="Cancel"
            width={960}
          >
            <Typography.Paragraph strong={true} type="warning">
              Are you sure you want to override statuses for {numKitsReviewed}{" "}
              out of {kitReviewOrder.length} kits?
            </Typography.Paragraph>
            <Typography.Paragraph italic={true}>
              Clicking submit will publish your overrides to our pipeline. A
              copy of the overrides.csv will be downloaded to your machine. You
              should see a success or error message on the screen here; however,
              you can visit #pipeline-notifications and #pipeline-alerts to
              verify the final state of the pipeline. Please direct and
              questions to #eng-data-engineering. You can send us the
              overrides.csv to help troubleshoot any issues you have.
            </Typography.Paragraph>
          </Modal>
        </Col>
      </Row>
      <Row className="ml-3 mr-3 mt-3">
        <Col>
          <Title level={3}>{assayDisplay + " Review"}</Title>
        </Col>
      </Row>
      <Row className="ml-3 mr-3 mt-1">
        <Col>
          <Typography.Text>
            The following aliquots are in a pending state. Click "Start Review"
            to go through each kit and manually review each aliquot. Your
            statuses will be saved to your local browser cache until all
            aliquots have been reviewed and you submit the overrides at the end
            of the workflow. If you leave or refresh this page, your changes
            will be preserved.
          </Typography.Text>
        </Col>
      </Row>
      <Row justify="start" className="ml-3 mr-3 mt-5">
        <Col>
          <Button
            type="primary"
            icon={<DownloadOutlined onClick={handleDownloadKitsMissingLogs} />}
          />
          <Typography.Text className="ml-2">
            {statusesMissingLogs.length} aliquots missing logs.
          </Typography.Text>
          <Popover
            content={missingLogTip}
            placement="right"
            className="ml-1"
            //overlayStyle={{ "max-width": "300px" }}
          >
            <QuestionCircleOutlined />
          </Popover>
        </Col>
      </Row>
      <Row className="ml-3 mr-3 mt-3">
        <Col span={24}>
          <StatusesTable
            assayReviewState={assayState}
            originalStatuses={reviewableStatuses}
            kitReviewOrder={kitReviewOrder}
            onKitSelected={handleNextKit}
          />
        </Col>
      </Row>
    </>
  );
};

export default AssaySummary;
