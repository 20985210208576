import React from "react";
import { Button } from "antd";

function AdminToggleButton({ buttonText, toggleView }) {
  return (
    <Button type="primary" size="large" onClick={() => toggleView()}>
      {buttonText}
    </Button>
  );
}

export default AdminToggleButton;
