import { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import Loader from "components/Loader/Loader";
import { validateToken } from "utils";
import Layout from "containers/Layout";

export default function PublicRoute({
  component: Component,
  restricted,
  ...options
}) {
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  const route = <Route component={Component} {...options} />;

  useEffect(() => {
    validateToken().then((res) => setIsLoggedIn(restricted && res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!restricted) {
    return <Layout route={route} menu={false} isPublic={true} {...options} />;
  }

  if (restricted && isLoggedIn === true) {
    return <Redirect to="/orders" {...options} />;
  }

  if (restricted && isLoggedIn === false) {
    return (
      <Layout
        route={route}
        menu={false}
        footer={false}
        isPublic={true}
        {...options}
      />
    );
  }

  return <Loader />;
}
