// external
import { createRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, PageHeader, Col, Row, Typography } from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment";

// components
import Loader from "components/Loader/Loader";
import AddressFormModal from "components/AddressFormModal/AddressFormModal";

// order form section components
import ProductList from "./components/ProductList/ProductList";
import OrderCycles from "./components/OrderCycles/OrderCycles";
import ShippingLocations from "./components/ShippingLocations/ShippingLocations";
import ShippingDate from "./components/ShippingDate/ShippingDate";
import ShippingSpeed from "./components/ShippingSpeed/ShippingSpeed";
import OrderSummary from "./components/OrderSummary/OrderSummary";
import OrderLegal from "./components/OrderLegal/OrderLegal";
import OrderConfirmEmails from "./components/OrderConfirmEmails/OrderConfirmEmails";
import DaysOfWeek from "./components/DaysOfWeek";
import { KitConfiguration } from "./components/KitConfiguration/KitConfiguration";
import { PROGRAM_VISUALIZATION_CODE } from "configs/constants";

// services/actions/helpers
import { useUserContext } from "context";
import {
  actionOrderEdit,
  actionOrderAdd,
  actionOrdersGet,
  actionOrderReset,
  actionAddressSave,
} from "actions";
import { placeOrder } from "./helpers";
import KitProvider from "./components/KitProvider";
import FulfillmentPriority from "./components/FulfillmentPriority";

import { useKitConfigurationList } from "api/kitConfiguration";
import { useSamplingLocationProgramEnrollmentList } from "api/samplingLocationProgramEnrollment";
import { useVendorList } from "api/vendor";

const { Text } = Typography;

const mapStateToProps = ({ location, order, orders, organization }) => ({
  location,
  order,
  orders,
  organization,
});

const mapDispatchToProps = {
  actionOrderAdd,
  actionOrderEdit,
  actionOrdersGet,
  actionOrderReset,
  actionAddressSave,
};

const OrderForm = ({
  location,
  order,
  orders,
  history,
  actionOrderAdd,
  actionOrderEdit,
  actionOrdersGet,
  actionOrderReset,
  actionAddressSave,
}) => {
  const formRef = createRef();

  const [loading, setLoading] = useState(false);
  const [showAddressFormModal, setShowAddressFormModal] = useState(false);
  const { organizationId, organizationContactEmail } = useUserContext();

  const {
    data: kitConfigurationList,
    isLoading: isKitConfigurationListLoading,
  } = useKitConfigurationList({
    _where: {
      active: true,
    },
  });

  const {
    data: kittingVendorList,
    isLoading: isKittingVendorListLoading,
  } = useVendorList({
    _where: {
      "services.code_in": "kitting",
      active: true,
    },
  });

  const today = moment().format("YYYY-MM-DD");
  const {
    data: activeOrgProgramEnrollments,
    isLoading: isActiveEnrollmentsLoading,
  } = useSamplingLocationProgramEnrollmentList({
    _where: {
      "sampling_location.organization": organizationId,
      start_date_lte: today,
      end_date_gte: today,
    },
  });

  const hasOnlyPublicVizEnrollments = activeOrgProgramEnrollments?.every(
    (program) => {
      return program.program.code === PROGRAM_VISUALIZATION_CODE;
    }
  );
  const isSkuSelectionRequired =
    !activeOrgProgramEnrollments?.length || hasOnlyPublicVizEnrollments;

  useEffect(() => {
    formRef.current?.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (organizationContactEmail) {
      actionOrderEdit({
        field: "confirmation_email",
        value: organizationContactEmail,
      });
    }
    actionOrderEdit({
      field: "requires_purchase_order",
      value: "false",
    });
    if (organizationId) {
      actionOrdersGet(organizationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  useEffect(() => {
    actionOrdersGet(organizationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    actionOrderReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const lastOrder = orders[orders?.length - 1];

  const handleAddressFormSubmit = (addressFormValue) => {
    const payload = { organization: organizationId, ...addressFormValue };
    actionAddressSave(payload).then(() => {
      setShowAddressFormModal(false);
    });
  };

  if (
    loading ||
    isActiveEnrollmentsLoading ||
    isKitConfigurationListLoading ||
    isKittingVendorListLoading
  ) {
    return <Loader />;
  }

  return (
    <>
      <PageHeader title="Order" />
      <Form
        ref={formRef}
        onFinish={(values) => {
          formRef.current.resetFields();
          placeOrder({
            order,
            organizationId,
            setLoading,
            history,
            actionOrderAdd,
            values,
          });
        }}
        onFinishFailed={(err) => {
          console.log(err);
        }}
        onFieldsChange={(values) => {
          // clear value if adding new address
          if (values[0] && values[0].name && values[0].value === "new") {
            setShowAddressFormModal(true);
            formRef.current.setFieldsValue({
              [values[0].name]: null,
            });
          }
          // map fields to store.order
          if (values[0] && values[0].name) {
            actionOrderEdit({
              field: values[0].name,
              value: values[0].value,
            });
          }
        }}
        scrollToFirstError={true}
        name="order-form"
        colon={false}
        layout={"vertical"}
        initialValues={{
          sampling_schedule: lastOrder ? lastOrder.sampling_schedule : [],
          confirmation_email: organizationContactEmail,
          requires_purchase_order: "false",
          send_emails: ["order_confirmation"],
          fulfillment_priority: "Medium",
          kit_configuration:
            kitConfigurationList?.length === 1
              ? kitConfigurationList?.[0].id
              : null,
          fulfillment_provider:
            kittingVendorList?.length === 1 ? kittingVendorList?.[0].id : null,
        }}
        className="pl-3"
      >
        <Row className="order-form__row">
          <Col sm={16} xs={24}>
            {isSkuSelectionRequired ? (
              <ProductList />
            ) : (
              <Row className="mb-3">
                <Text type="warning">
                  SKU selection is not required—organization is currently
                  enrolled in one or more programs
                </Text>
              </Row>
            )}
            <OrderCycles />
            <ShippingLocations />
            <ShippingDate />
            <ShippingSpeed />
            <OrderLegal />
            <DaysOfWeek />
            <KitConfiguration kitConfigs={kitConfigurationList} />
            <KitProvider kittingVendors={kittingVendorList} />
            <FulfillmentPriority />
            <OrderConfirmEmails />
            <AddressFormModal
              visible={showAddressFormModal}
              onSubmit={handleAddressFormSubmit}
              onCancel={() => setShowAddressFormModal(false)}
            />
          </Col>

          <Col sm={8} xs={24} data-cy="order-summary">
            <OrderSummary kitConfigs={kitConfigurationList} />
          </Col>
        </Row>
      </Form>
    </>
  );
};

const OrderFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderForm);
export default withRouter(OrderFormContainer);
