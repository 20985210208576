import { FnRun as ExportKitAssayAssignments } from "./fn-gtm-export-kit-assay-assignments";

import { FnRun as ExportOrders } from "./fn-gtm-export-orders";

import { FnRun as ExportRerunRequests } from "./fn-gtm-export-rerun-requests";

import { FnRun as ImportKitsReceived } from "./fn-gtm-import-kits-received";

import { FnRun as ImportOrderFulfillments } from "./fn-gtm-import-order-fulfillments";

const pages = {
  "gtm-export-kit-assay-assignments": ExportKitAssayAssignments,
  "gtm-export-orders": ExportOrders,
  "gtm-export-rerun-requests": ExportRerunRequests,
  "gtm-import-kits-received": ImportKitsReceived,
  "gtm-import-order-fulfillments": ImportOrderFulfillments,
};

export default pages;
